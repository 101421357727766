import { useStore } from "@/store"
import { observer } from "mobx-react-lite"
import { useContext, useEffect, useState } from "react"
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Button, Dialog, IconButton, Snackbar, TextField } from "@mui/material";
import PlanPrice from "../plan-price";
import useDataApi from "@/hooks/useDataApi";
import { ThemeContext } from "@/theme/themeContext";

const PlanModal = (props)=>{
    const {appStore} = useStore()
    const {theme, toggleTheme} = useContext(ThemeContext)

    const [{dataResult, isLoading, isError, pageNum}, setQueryParams] = useDataApi({
        url: '/api/ilovegpt/listAllPlan'
    })

    const handlePlanClose = ()=>{
        appStore.planOpen = false
    }

    return(
        <Dialog open={appStore.planOpen} onClose={handlePlanClose} >
            <DialogContent className={` ${theme==='dark' ? "bg-lightBlack" : "bg-white" } `}>
                <PlanPrice planData={dataResult} showAlert={props.showAlert} />
            </DialogContent>
        </Dialog>
    )
}
export default observer(PlanModal)