import hljs from 'highlight.js';
import { Marked } from 'marked';
import { markedHighlight } from 'marked-highlight';

const MarkdownParse = (props) => {
    //替换掉\n为br,否则react中渲染失效，无法实现回车换行
    const content = props.content.replace(/\n/g, '<br>')

    const marked = new Marked(markedHighlight(
        { //不起效果....
            langPrefix: 'hljs language-',
            highlight(code, lang) {
                const language = hljs.getLanguage(lang) ? lang : 'plaintext';
                return hljs.highlight(code, { language }).value;
            }
        }
    ))

    const renderer = new marked.Renderer();

    /**
     * 替换掉代码块中的<br>字符为\n，要使用转义字符代表'<'和'>'
     * @param {*} code 
     * @returns 
     */
    renderer.codespan = function (code) {
        if (code.indexOf('&lt;br&gt;') !== -1) {
            code = code.replace(/&lt;br&gt;/g, '\n')
        }
        return `<pre class="whitespace-pre-wrap"><code>${code}</code></pre>`
    }

    const html = marked.parseInline(content, {
        renderer: renderer
    })

    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: html }} />
        </>
    )
}
export default MarkdownParse