
import { PlusIcon } from '@/constants';
import { GPTIcon } from '@/constants';
import { CautionIcon } from '@/constants';
import { ChatIcon } from '@/constants';
import { LightningChargeIcon } from '@/constants';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { useStore } from '@/store';
import { useContext } from 'react';
import { ThemeContext } from '@/theme/themeContext';

const Welcome = () => {
    const {appStore} = useStore()
    const {theme, toggleTheme} = useContext(ThemeContext)

    const handleClickPlan = ()=>{
        appStore.planOpen = true
    }

    return (
        <>
            <div className="justify-center items-center w-full md:max-w-2xl lg:max-w-3xl flex flex-col pt-20 px-6 pb-6">
                <div className={`flex flex-row font-semibold text-5xl ${theme==='dark' ? "text-gray-300" : "text-lightBlack"} `}>I&nbsp;Love&nbsp;
                    <p className="font-extralight">GPT</p>
                </div>

                <div className="flex flex-row pt-3">
                    👋&nbsp;&nbsp;<p className={`${theme==='dark' ? "text-gray-100/50" : "text-gray-500"}   text-sm font-light`}>你好！有什么我能帮到你的吗？</p>
                </div>

                <div className="flex flex-row pt-10 gap-x-6">
                    <a onClick={handleClickPlan} className="flex items-center gap-3 rounded-lg bg-red-500 px-3 py-2  hover:bg-gray-600 transition-colors duration-400  cursor-pointer text-sm flex-shrink-0  border-white/20">
                        <CardGiftcardIcon fontSize="small" />高级功能
                    </a>
                </div>
            </div>
        </>
    )
}
export default Welcome;