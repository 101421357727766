import { DateRange, Home } from "@mui/icons-material";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AppsIcon from '@mui/icons-material/Apps';
import CopyrightIcon from '@mui/icons-material/Copyright';
import React, { useContext, useEffect, useState } from "react";
import TranslateIcon from '@mui/icons-material/Translate';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FeedIcon from '@mui/icons-material/Feed';
import SmsIcon from '@mui/icons-material/Sms';
import CampaignIcon from '@mui/icons-material/Campaign';
import WorkIcon from '@mui/icons-material/Work';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CodeIcon from '@mui/icons-material/Code';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import CreateIcon from '@mui/icons-material/Create';
import AbcIcon from '@mui/icons-material/Abc';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import ImageIcon from '@mui/icons-material/Image';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeTwoToneIcon from '@mui/icons-material/LightModeTwoTone';

import {
  DiscordIcon,
  ExternalLinkIcon,
  LogOutIcon,
  PlusIcon,
  SunIcon,
} from "../../constants";
import { useNavigate } from "react-router-dom";
import useDataApi from "@/hooks/useDataApi";
import { useStore } from "@/store";
import { observer } from "mobx-react-lite";
import { green } from "@mui/material/colors";
import zIndex from "@mui/material/styles/zIndex";
import { data } from "autoprefixer";
import { ThemeContext } from "@/theme/themeContext";

const LeftMenu = () => {
  const { chatStore, appStore } = useStore()
  const [show, setShow] = useState(false)

  const navigate = useNavigate()
  const [{ dataResult, isLoading, isError, pageNum }, setQueryParams] = useDataApi({})

  const { theme, toggleTheme } = useContext(ThemeContext)

  useEffect(() => {
    setQueryParams({
      url: 'api/ilovegpt/syncStuff'
    })
  }, [])

  useEffect(() => {
    if (dataResult && dataResult.code === 200 && !Array.isArray(dataResult.data) && !isError) {
      chatStore.updateQuantity(dataResult.data.quantity)
      chatStore.resetPlanName(dataResult.data.planName)
      chatStore.resetConsumeQuantity(dataResult.data.consumeQuantity)
      chatStore.updateConsumeQuantity(0)
    } else if (dataResult && (dataResult.code === 505 || dataResult.code === 408)) {
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
    }
  }, [dataResult, isLoading])

  /**
   * 窗口监听
   * 当前是移动端，而且目前显示菜单情况下，进行隐藏菜单操作
   */
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      if (isMobile && appStore.mobileMenuShow) {
        appStore.updateMobileMenu(!isMobile)
      }
    };

    // 在组件挂载时和窗口大小变化时触发handleResize函数
    window.addEventListener('resize', handleResize);
    //初始化时调用一次以确保正确设置初始值
    handleResize();
    // 清理事件监听器
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])


  const cases = [
    {
      name: '聊天Bot',
      menuIcon: <SmsIcon fontSize="small" />,
      href: 'chat-bot',
      systemContent: '',
      promptWrapper: '',
      description: '生成式AI陪你聊！',
      model: 'GPT-3.5-turbo',
      localStorageKey: 'chatBot-localstorage'
    },

    {
      name: '解决方案',
      menuIcon: <EmojiObjectsIcon fontSize="small" />,
      href: 'solution',
      systemContent: '你是一位解决方案编写专家，根据我写的题目和提出的要求写一篇完整的解决方案，下面是我的题目\n\n',
      promptWrapper: ' """ {} """ ',
      description: '根据题目一键生成解决方案，要求提供完整的背景和要求，以及专业术语等，示例如下:' +
        '<div><br/></div>' +
        '<div>1.&nbsp;Apple数据中心运营管理标准化实施方案</div>' +
        '<div>2.&nbsp;结合当地电力成本和人力成本实施</div>' +
        '<div>3.&nbsp;根据当地政府对于企业扶持和税收政策因地制宜指定方案</div>',
      model: 'GPT-3.5-turbo',
      localStorageKey: 'solution-localstorage'
    },

    {
      name: '单位通讯稿',
      menuIcon: <FeedIcon fontSize="small" />,
      href: 'newsletter',
      systemContent: '你是一位在企业上班的办公室工作人员，负责单位通讯稿、微信公众号编写工作，请根据我输入的主题内容写一篇通讯稿，要求行文风格按照示例通讯稿，但是绝对不能出现示例中的关键信息，如公司名称，领导姓名以及项目名称等信息等，我的示例通讯稿如下：' +
        ' "标题： 总经理调研北京农业中关村智慧蔬菜工场项目， 6月5日至6日，我公司党委副书记、总经理某某某赴北京市平谷区调研北京农业中关村智慧蔬菜工场项' +
        '目建设情况。6月5日，总经理考察了北京农业中关村智慧蔬菜工场项目施工现场，全面了解项目的质量、安全、进度情况，对施工过程中存在的问题提出具体要求。他指出，项目' +
        '指挥部要进一步深入学习习近平总书记关于安全生产工作的重要论述和指示批示精神，时刻绷紧安全生产这根弦，加大安全监管力度，不留死角，确保生产安全；以“严”、' +
        '“慎”、“细”、“实”的工作作风，严把质量关，对生产全过程实行质量管控，保证项目质量；要科学管理，精心组织，优化资源配置，合理安排工期，对项目关键节点，要克服困' +
        '难，全力以赴按时完成项目建设。6月6日，北京气温升到37℃，总经理再次赶赴项目现场，向奋战在高温环境的一线员工表示慰问，他详细了解现场人员的工作和生活情况，叮' +
        '嘱大家在工作中要做好防暑降温，并为大家送上降温凉茶。"\n\n 我的主题内容如下：',
      promptWrapper: ' """ {} """ ',
      description: '单位公众号发文，通讯稿一键撰写，只需填写关键内容即可，无需其他内容，示例如下：' +
        '<div><br/></div>' +
        '<div>1.&nbsp;北京农业中关村智能物流项目现场调研</div>' +
        '<div>2.&nbsp;公司总经理现场慰问项目实施人员</div>' +
        '<div>3.&nbsp;完成了项目第一阶段的需求调研和物资采购等准备工作</div>' +
        '<div>4.&nbsp;制定了完备的项目实施计划，公司内部开了项目动员大会</div>' +
        '<div>5.&nbsp;公司全体人员斗志昂扬，准备大干60天完成既定项目实施计划</div>',
      model: 'GPT-3.5-turbo',
      localStorageKey: 'newsletter-localstorage'
    },

    {
      name: '领导发言稿',
      menuIcon: <CampaignIcon fontSize="small" />,
      href: 'leader-speech',
      systemContent: '你是一位国企集团上市公司下全资子公司的总经理，现在需要根据我的要求写一份工作总结发言稿，请严格按照示例发言稿的行文风格和语气进行撰写，格式也要保持一致，并且根据我的具体要求，先生成主要大纲，然后根据每个大纲展开写出正文内容，最后要润色整篇发言稿，最后返回发言稿即可，不需要附带任何额外的解释，示例发言稿如下：' +
        '标题： 立足集团 做好保障 推进平台建设落地应用在2011年工作务虚会上的发言稿 \n\n' +
        '各位领导、各位同事： \n\n' +
        '今年以来，按照公司总体战略部署，3月对研究中心进行机制调整。软件事业部成立后，设立产品线+职能化管理模式，通过对事业部经营指标的层层分解，推动产品线实现自负盈亏、自担责任的自控自转运行轨道。对外，积极寻求外部合作资源，省外市场完成了多个项目合同签订；对内，落地改革成效，聚焦产品，深化研发，各项重点任务和经营指标取得阶段性突破 \n\n' +
        '2012，我们将在“立足集团业务，做好信息保障、深化产品质量、提升客户满意度，做好平台搭建，加快人才建设”等方面全力推进。结合实际工作情况，我的发言分成两个部分。 \n\n' +
        '第一部分，谈谈本年度工作中存在的不足和短板。（第一部分从两个方面（业务和人才）三块内容展开） \n\n' +
        '首先，先简单介绍下事业部当前的经营情况。 \n\n' +
        '近期，围绕大讨论专题，通过各产品线的业务跟进排查，我们系统对在建及拟建项目进行业务回访，结合业主反馈情况来看，在自身“业务发展、项目维系、人才建设”上也还存在不少短板，具体表现为： \n\n' +
        '第一个方面，业务发展存在的短板。 \n\n' +
        '第二个方面，客户满意度不高。 \n\n' +
        '第三个方面，人才方面存在的短板。 \n\n' +
        '发言第二部分，立足服务集团，做好信息化保障，落地平台应用，谈谈想法和举措（从四个“做好”展开）。\n\n' +
        '第一个做好，做好高新化转型，加强核心能力，增强发展推力。 \n\n' +
        '第二个做好，做好专业化保障，落地平台应用，发挥好单元支撑作用（从事业部三条产品线切入）。 \n\n' +
        '第三个做好，做好市场化突破，拓展业务空间，增强发展活力。 \n\n' +
        '第四个做好，做好人才建设，提升效能，凝聚活力。 \n\n' +
        '发言结束，谢谢！\n\n 下面的是我的具体要求:',
      promptWrapper: ' """ {} """ ',
      description: '领导发言稿一键生成，填写核心要点即可，无需填写其他，示例如下:' +
        '<div><br/></div>' +
        '<div>1.&nbsp;集团下属研究院年终业务座谈会发言稿</div>' +
        '<div>2.&nbsp;项目交付总体金额达到了一个亿，创造了研究院项目交付历史最高记录</div>' +
        '<div>3.&nbsp;人才引进方面存在短板，激励机制不够，项目研发过程管理存在滞后问题</div>' +
        '<div>4.&nbsp;做好专业化保障，落地平台应用，深挖客户后续需求和项目拓展能力</div>',
      model: 'GPT-3.5-turbo',
      localStorageKey: 'leader-speech-localstorage'
    },

    {
      name: '述职报告',
      menuIcon: <WorkIcon fontSize="small" />,
      href: 'work-report',
      systemContent: '你是一位公司中层管理干部，现在需要根据我的要求写一份任职述职报告，请严格按照示例的报告进行行文撰写，行文风格和示例报告一致，格式也要一致，示例报告如下：' +
        '标题： 某某有限公司干部述职报告 \n\n' +
        '2020年，本人始终坚持以习近平新时代中国特色社会主义思想为指导，围绕公司年度重点任务和部门业绩责任书，在党的建设、纪检工作、干部管理、人才开发、企业文化、青年工作' +
        '等方面开展了大量基础性工作，取得一定成效，现将相关工作汇报如下：\n\n 第一部分 2020年的工作回顾和总结 \n\n ' +
        '以人才强企战略2.0为牵引，围绕公司三大业务发展方向，着力从“专业+系统+平台”搭建公司人才体系。截至目前，公司正式员工100人，派遣员工20人。正式员工中博士研究生5人，' +
        '硕士研究生21人，在组织架构设计调整、人才招聘、职工培训、员工关系、干部管理、薪酬绩效体系等业务方面开展各项工作...... \n\n ' +
        '一、党建、纪检工作开展情况\n\n' +
        '㈠ 党建引领中心工作开展\n\n' +
        '部门组织拟定年度党建工作要点。修订完善党总支工作规则、党支部工作规则、党建工作考核等制度，深入推进党建与中心工作深度融合.....\n\n' +
        '二、干部管理、人力资源及团委工作情况\n\n' +
        '㈠ 不断规范干部管理。拟定《公司中层领导人员管理规定》，全年完成7名干部平调，8名试用期满干部的转正、3名干部提干、10余名干部聘任流程。\n\n' +
        '第二部分 年度工作中取得的成绩和经验\n\n' +
        '一是按照党委工作要求，本人有幸参加了本年度党委巡察工作，并履行好第一巡察组副组长职责，巡察工作得到上级党委和纪检部门肯定，纪检工作履职能力进一步提升\n\n' +
        '二是建立三类人才（管理、技术、营销）职业生涯规划及成长通道，组织两次专题会议\n\n' +
        '第三部分 工作存在问题及不足\n\n' +
        '一是对上汇报、跨部门沟通，工作还不够热情，自身修养、业务学习不够\n\n' +
        '第四部分 2021年工作计划和打算\n\n' +
        '一、扎实推进党的政治建设及纪律建设\n\n' +
        '一是扎实做好公司党建工作，加强党支部规范化标准化建设，力争2020年度党建考核结果为“优秀”等级。\n\n' +
        '二、建立基于公司发展战略的人力资源规划\n\n' +
        '一是充分发挥党总支的领导核心和政治核心作用，深化劳动、人事、分配的“三项”制度改革\n\n' +
        '第五部分 对本部门、公司今后发展的设想、思路、建议\n\n' +
        '一是建议公司班子领导要高度关注研发人才梯队建设\n\n' +
        '二是对于员工满意度访谈和测评，不光是人力资源部门的工资，各业务部门领导也要俯下身、关注员工思想动态，关注对员工岗位工作的分工、协作与支持、支撑，减少核心骨干流失。\n\n' +
        '下面的是我的要求：\n\n',
      promptWrapper: ' """ {} """ ',
      description: '单位员工、干部述职报告一键生成，填写核心要点即可，无需填写其他，示例如下:' +
        '<div><br/></div>' +
        '<div>1.&nbsp;本人王思聪，任人力资源部部门经理</div>' +
        '<div>2.&nbsp;在组织架构设计调整、人才招聘、职工培训、员工关系、干部管理、薪酬绩效体系等业务方面开展各项工作</div>' +
        '<div>3.&nbsp;打通基于业务发展的营销、技术、管理人才成长三通道</div>' +
        '<div>4.&nbsp;推进党风廉政建设和反腐败工作持续开展</div>' +
        '<div>5.&nbsp;建立三类人才（管理、技术、营销）职业生涯规划及成长通道</div>' +
        '<div>6.&nbsp;人力资源工作属于政策性指导较强的工作，本人还要加强业务知识的学习</div>' +
        '<div>7.&nbsp;建议公司班子领导要高度关注研发人才梯队建设</div>',
      model: 'GPT-3.5-turbo',
      localStorageKey: 'work-report-localstorage'
    },
    {
      name: '旅行计划',
      menuIcon: <FlightTakeoffIcon fontSize="small" />,
      href: 'travel',
      systemContent: '你是一位旅行社的导游，请根据我的要求，设计一份详细的出游方案，必须包括明确的费用和线路，根据具体人数和游玩项目进行计算，并详细列出费用清单。方案的用词要客气并且专业。下面是我的要求:',
      promptWrapper: ' """ {} """ ',
      description: '详细的旅游路线方案，包括门票住宿以及路线等，只需列出路线出发地和目的地,出游人数,最想看的旅游景点等',
      model: 'GPT-3.5-turbo',
      localStorageKey: 'travel-localstorage'
    },

    {
      name: '公司活动策划',
      menuIcon: <SportsScoreIcon fontSize="small" />,
      href: 'activity',
      systemContent: '你是公司行政人员，请根据我的要求，设计一份详细的公司集体活动策划方案，请严格按照示例方案行文撰写，行文风格和示例一致，格式也要一致，示例方案如下：\n\n' +
        '标题：XX公司集体活动策划方案\n\n' +
        '为了加强公司员工的凝聚力和向心力，调动员工的工作热情和斗志，增强组织活力，改善组织文化，放松心情，提升组织整体沟通与协调的精神和能力，同时欢迎新员工的加入，让新员工尽快融入公司，培养新员工对公司的信任感和归属感。人事部决定于本周六前往良庆镇蚂蚁庄园参加一天的户外拓展活动，具体流程如下：\n\n' +
        '一、活动主题：猜灯谜、玩博饼、明月寄相思\n\n' +
        '二、活动时间：2015年9月18日（周五）8:30-20:00 \n\n' +
        '三、活动地点：良渚龙架山\n\n' +
        '四、活动内容：趣味比赛、自由游乐、自助午餐\n\n' +
        '五、相关准备\n\n' +
        '1.由行政、人事部预先做好前期准备事宜，做好活动安排；\n\n' +
        '2.相机；(可以上传到群空间里面)\n\n' +
        '3.横幅、气球、丝带。\n\n' +
        '六、人员：公司全体员工（约100人）\n\n' +
        '七、活动内容\n\n' +
        '1.“方程式卡丁赛车”\n\n' +
        '2.自由活动：可根据自己兴趣爱好，自由选择滑草、滑车等项目，但必须注意安全。\n\n' +
        '3.自助午餐&颁奖：尽享饕餮大餐，开餐前由领导班子为比赛获奖的人员颁奖。\n\n' +
        '八、活动流程\n\n' +
        '上午：\n\n' +
        '1.8:20在公司楼下集合，8:30准时出发\n\n' +
        '2.8:30——9:30 乘车到良渚龙架山\n\n' +
        '3.9:30——9:40大门口全体合影\n\n' +
        '4.9:40——10:00趣味比赛 《方程式卡丁赛车》\n\n' +
        '九、温馨提示\n\n' +
        '1.请大家务必要注意安全，要视自己身体状况选择游玩项目。\n\n' +
        '2.为了玩地尽兴，请大家在活动当天都穿运动装和运动鞋，女员工请不要穿裙子。\n\n' +
        '3.活动期间，请大家听从统一指挥，多多配合，谢谢大家！ \n\n' +
        '行政部 2015年7月7日\n\n' +
        '下面是我的要求：',
      promptWrapper: ' """ {} """ ',
      description: '详细的公司活动策划方案一键生成，填写活动重点内容和流程，示例如下：' +
        '<div><br/></div>' +
        '<div>1.&nbsp;公司户外拓展活动策划方案</div>' +
        '<div>2.&nbsp;全体人员去往北京昌平参加爬灵山活动，组织篝火晚会吃烤全羊</div>' +
        '<div>3.&nbsp;周六上午8:30从公司坐大巴出发，周日晚19点返回公司</div>' +
        '<div>4.&nbsp;可以携带家属，请提前报备到行政办公室处，请各自照顾好各自家属</div>',
      model: 'GPT-3.5-turbo',
      localStorageKey: 'activity-localstorage'
    },

    {
      name: '年终总结',
      menuIcon: <SummarizeIcon fontSize="small" />,
      href: 'year-summary',
      systemContent: '你是一名公司的员工，年底了，需要写一份详细的年终总结，请根据我提出的具体要求和提供的公司背景情况，\n' +
        '以及你的工作内容，写一篇年终总结报告，必须突出你个人工作的努力，报告内容要充实和详尽，报告不能喊口号，你在语言表达上要有一点煽情和自夸，最后要润色整篇报告。\n' +
        '你的背景情况如下：',
      promptWrapper: ' """ {} """ ',
      description: '填写你在公司的基本情况和工作职责，生成一份完美的年(终/中)总结，示例如下:' +
        '<div><br/></div>' +
        '<div>1.&nbsp;你在公司的岗位是办公室主任兼纪委委员，管理公司绩效考核和党风党纪建设，人力资源，党风建设</div>' +
        '<div>2.&nbsp;全年完成了人力资源制度建设，开了三次党支部会议，学习了党风党纪课程</div>' +
        '<div>3.&nbsp;今年经常加班加点</div>' +
        '<div>4.&nbsp;完成了绩效体系建设</div>' +
        '<div>5.&nbsp;由于加班不能及时回家陪伴家人</div>',
      model: 'GPT-3.5-turbo',
      localStorageKey: 'year-summary-localstorage'
    },

    {
      name: '请示材料',
      menuIcon: <BubbleChartIcon fontSize="small" />,
      href: 'request-materials',
      systemContent: '你是一名国企员工，需要写一份请示材料，上党委会进行审议，请根据我提出的具体要求来写，并且严格按照我提供的示例请示材料撰写，行文风格和示例一致，格式也要一致，示例如下：\n\n' +
        '标题：关于审定《某某公司计划管理办法》的请示\n\n' +
        '公司党总支会：\n\n' +
        '根据公司规章制度体系建设总体规划要求及业务实际需要，行政办公室编制了《某某公司计划管理办法》（以下简称《计划管理办法》）。目前，《计划管理办法》已通过主管领导审核，现按程序提请公司党总支会审定。\n\n' +
        '一、议题背景\n\n' +
        '根据公司制度体系建设要求及业务实际需要，新制定《计划管理办法》。\n\n' +
        '二、主要目的\n\n' +
        '㈠ 为落实公司战略和规划发展目标，增强计划管控力度。\n\n' +
        '㈡ 构建“制定完善、指标科学、流程清晰、控制有力”的计划管理体系，合理安排公司各项经营工作。\n\n' +
        '㈢ 提高公司经营效率、盈利能力、竞争能力和可持续发展能力，促进公司又好又快发展。\n\n' +
        '三、主要参考文件\n\n' +
        '无。\n\n' +
        '四、《计划管理办法》制定过程\n\n' +
        '2014年1月1日，完成《计划管理办法》修订和公司内部审查。\n\n' +
        '2015年1月2日，经主管领导审议通过。\n\n' +
        '2016年1月4日，按程序提交会议决策流程。\n\n' +
        '五、主要内容\n\n' +
        '《计划管理办法》共十条，详见附件。\n\n' +
        '六、审查重点及难点\n\n' +
        '㈠ 计划的分类（第二条）\n\n' +
        '公司的计划分为公司级计划和事业部级计划。\n\n' +
        '㈡ 计划管理的职责（第五条）\n\n' +
        '计划管理部负责公司级计划目标制定、过程督促、信息收集、跟踪反馈、检查考核；负责对各部门级计划的内容完成情况信息收集、检查考核。\n\n' +
        '七、与上级相关规章制度衔接关系的处理情况\n\n' +
        '《计划管理办法》规定中的原则、定义、格式、行文规则、工作流程等均与上级规定保持一致，符合集团公司相关规章制度要求，切合公司实际情况。\n\n' +
        '八、请示事项\n\n' +
        '建议审议通过行政办公室提交的《某某公司计划管理办法》，尽快印发执行。\n\n' +
        '附件：某某公司计划管理办法\n\n' +
        '行政办公室 2016年1月4日\n\n' +
        '下面是我的具体要求： ',
      promptWrapper: ' """ {} """ ',
      description: '领导请示报告一键生成，填写请示背景，以及请示要点即可，示例如下:' +
        '<div><br/></div>' +
        '<div>1.&nbsp;关于审批某某公司《项目管理云平台》研发费用的请示</div>' +
        '<div>2.&nbsp;为了尽快完成云平台研发，按时交付集团公司，按时上线</div>' +
        '<div>3.&nbsp;重点审查项目研发总费用100万，其中30万用于购置研发电脑和其他配件问题</div>' +
        '<div>4.&nbsp;审查难点在于研发费用的审批流程，希望缩短审批流程，否则耽误研发周期和项目上线时间</div>' +
        '<div>5.&nbsp;请示尽快完成审批通过，顺利按时完成项目交付工作</div>',
      model: 'GPT-3.5-turbo',
      localStorageKey: 'request-materials-localstorage'
    },

    {
      name: '文章摘要',
      menuIcon: <DocumentScannerIcon fontSize="small" />,
      href: 'article-summary',
      systemContent: "You will analyze a article structure, use a list format to provide, brief summary of a article. and finally conclude a article. 用中文回答",
      promptWrapper: ' """ {} """ ',
      description: '帮你生成长篇文章的大纲和概要信息，并对文章进行总结，只需输入文章内容，无需其他多余内容',
      model: 'GPT-3.5-turbo',
      localStorageKey: 'article-summary-localstorage'
    },

    {
      name: '找图片啊',
      menuIcon: <ImageIcon fontSize="small" />,
      href: 'find-pic',
      systemContent: '你是一位美术老师',
      promptWrapper: '请找1副 """ {} """ 图片，图片请使用 https://source.unsplash.com/960×640/，并采用Markdown格式显示，不需要返回任何额外的解释',
      description: '输入你想要的图片关键词，生成随机图片',
      model: 'GPT-3.5-turbo',
      localStorageKey: 'find-pic-localstorage'
    },

    {
      name: '代码解释器',
      menuIcon: <CodeIcon fontSize="small" />,
      href: 'code-read',
      systemContent: '你是一位计算机专家，请根据我输入的一段代码来分析其结构，要求如下：\n*提供详尽语法结构解释 \n*找出这段代码里面的bug \n下面是我的代码片段 ',
      promptWrapper: ' """ {} """ ',
      description: '深度分析和解读代码逻辑，找出代码中的Bug，只需输入代码片段，无需其他多余内容',
      model: 'GPT-3.5-turbo',
      localStorageKey: 'code-read-localstorage'
    },
    {
      name: '英语长难句',
      menuIcon: <AbcIcon fontSize="small" />,
      href: 'english-sentence',
      systemContent: '你是一位英语老师，请根据我输入的句子使用中文进行句子的语法结构分析，并提供详细的结构解释，最后翻译句子为中文，要求：\n' +
        "*列出句子中所有的复杂的单词\n" +
        "*句子分析分为三步来剖析\n" +
        "*最后翻译全句\n" +
        "下面是一个例句的分析过程，比如：\n" +
        "输入: \n" +
        "At a time when Thomes Piketty and other economists are warming of rising inequality and the increasing power of inherited wealth, it is bizarre that wealthy aristocratic families should still be the symbolic heart of modern democratic states.\n" +
        "输出：\n" +
        "【生词】\n" +
        "inherit v. 继承，继承所得\n\n" +
        "【句子分析】\n" +
        "第一步：断句\n" +
        "本句话可以依据标点符号将句子断成两个部分：at a time when Thomes Piketty and other economists are warming of rising inequality and the increasing power of inherited wealth以及it is bizarre that wealthy aristocratic families should still be the symbolic heart of modern democratic states。\n" +
        "第一部分中，at a time是介词短语做时间状语，之后when所引导的从句，在名词time之后，所以是定语从句。该定语从句中Thomes Piketty and other economists是主语，are warning of是谓语部分，rising inequality和the increasing power of inherited wealth是并列的宾语部分。\n" +
        "第二个部分中，it是形式主语，is是系动词，bizarre是句子的表语，而之后that所引导的从句是主语从句。从句中，wealthy aristocratic families是主语，should still be是系动词，the symbolic heart of modern democratic states是表语。\n" +
        "第二步：逐步翻译\n" +
        "第一部分：在托马斯.皮凯蒂以及其它一些经济学家纷纷对日益严重的不平等和日益增强的世袭财权发出警告的时候\n" +
        "第二部分：怪异的是富有的贵族家庭竟然还是现代民主国家的核心象征\n" +
        "第三步：组合整句\n" +
        "第一部分为时间状语从句，需要前置到修饰的主句之前，因此两个部分不需要调整语序。\n\n" +
        "【译文】在托马斯.皮凯蒂以及其它一些经济学家纷纷对日益严重的不平等和日益增强的世袭财权发出警告的时候，怪异的是富有的贵族家庭竟然还是现代民主国家的核心象征。",
      promptWrapper: '我的第一个句子是： """ {} """ ',
      description: '帮你详细分析长难句的语法结构和生词断句，只需输入长难句，无需其他多余的内容',
      model: 'GPT-3.5-turbo',
      localStorageKey: 'english-sentence-localstorage'
    },
    {
      name: '写作指导',
      menuIcon: <CreateIcon fontSize="small" />,
      href: 'writing',
      systemContent: '你是一位中文写作老师，请根据我输入的主题内容指导我完成一篇文章，要求：\n' +
        '*告诉我应该如何构造文章的结构\n' +
        '*从哪些方面围绕主题内容展开\n' +
        '*列出短文的大纲\n' +
        '我的主题内容是 ',
      promptWrapper: ' """ {} """ ',
      description: '根据你输入的主题内容指导完成一篇文章的创作，只需输入文章的主题内容即可，无需其他多余的内容',
      model: 'GPT-3.5-turbo',
      localStorageKey: 'writing-localstorage'
    }
  ]

  const onCaseHandle = (_case) => {
    const isMobile = window.innerWidth <= 768;
    //移动端情况下，点击菜单自动隐藏
    if (isMobile) {
      appStore.toggleMobileMenu()
    }
    navigate('/' + _case.href, { state: { "name": _case.name, "href": _case.href, "systemContent": _case.systemContent, "promptWrapper": _case.promptWrapper, "description": _case.description, "model": _case.model, "localStorageKey": _case.localStorageKey } })
  }

  const handleWechatOpen = () => {
    appStore.wechatOpen = true
  }

  const handleChangelogOpen = ()=>{
    appStore.changelogOpen = true
  }

  return (

    <div className={` ${appStore.mobileMenuShow ? "flex flex-col absolute inset-y-0 z-10 top-0 left-0 w-[260px] " : "hidden"} md:fixed bg-black`} >
      <div className="flex h-full min-h-0 flex-col ">
        <div className="scrollbar-trigger flex h-full w-full flex-1 items-start border-white/20">
          <nav className="flex h-full flex-1 flex-col space-y-1 p-2">

            <a href="/" className="flex py-3 px-3 items-center gap-3 rounded-md transition-colors duration-200 text-gray-400 mb-4 flex-shrink-0">
              <SmartToyIcon color="success" />
              <p className="cursor-pointer text-1xl font-semibold">I Love GPT</p>
            </a>

            <div className="flex-col flex-1 overflow-y-auto border-b border-white/20">
              <div className="flex flex-col gap-2 text-gray-100 text-sm">
                {
                  cases.map((_case, index) => (
                    <a onClick={() => onCaseHandle(_case)} className="flex py-3 px-3 items-center gap-3 rounded-md hover:bg-gray-500/10 transition-colors duration-200 text-gray-100/50 cursor-pointer text-sm flex-shrink-0  border-white/20">
                      {_case.menuIcon}{_case.name}
                    </a>
                  ))
                }
              </div>
            </div>

            <div>
              <a className="flex py-3 px-3 items-center gap-3 rounded-md transition-colors duration-200 text-gray-100/50 text-xs">
                {chatStore.planName} <div className="bg-gray-500/10 px-2 py-2 rounded-md text-red-400">{chatStore.consumeQuantity}&nbsp;/&nbsp;{chatStore.quantity}&nbsp;tokens</div>
              </a>

              <a className="flex py-3 px-3 items-center gap-3 rounded-md transition-colors duration-200 text-gray-100/50 text-xs">
                <div className="bg-gray-500/10 px-1 py-1 rounded-md cursor-pointer" onClick={toggleTheme}>
                  {
                    theme === 'dark' ? <DarkModeOutlinedIcon fontSize="small" /> : <LightModeTwoToneIcon fontSize="small" />
                  }
                </div>
                <div className="bg-gray-500/10 px-1 py-1 rounded-md cursor-pointer" onClick={handleWechatOpen}>
                  <ContactSupportIcon fontSize="small" />
                </div>
              </a>

              <a className="flex px-3 pt-3 pb-0 items-center self-left gap-0.5 rounded-md transition-colors duration-200 text-gray-100/50 text-xs font-light">
                ILoveGPT.net<CopyrightIcon sx={{ fontSize: 15 }} />2023
              </a>
              <a className="flex px-3 pb-3 pt-0 items-center self-left gap-1 rounded-md transition-colors duration-200 text-gray-100/50 text-xs font-light">
                Privacy&nbsp;|&nbsp;FAQs&nbsp;|&nbsp;<p className="cursor-pointer hover:bg-slate-800" onClick={handleChangelogOpen}>Changelog</p>
              </a>
            </div>

          </nav>
        </div>
      </div>

    </div>
  );
};
export default observer(LeftMenu);
