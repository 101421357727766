import { CautionIcon, GPTIcon, LightningChargeIcon, PlaneIcon, SunIcon, UserIcon } from "../../constants";
import ChatBody from "../chat-body";
import { useContext, useEffect, useRef, useState } from "react";
import useCompletion from "../../hooks/useCompletion";
import Welcome from "../welcome";
import { useStore } from "@/store";
import { observer } from "mobx-react-lite";
import SmsIcon from '@mui/icons-material/Sms';
import { green, red } from "@mui/material/colors";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { ThemeContext } from "@/theme/themeContext";

const Home = () => {
    const navigate = useNavigate()
    const chatBodyRef = useRef(null)
    const { chatStore } = useStore()

    const {theme, toggleTheme} = useContext(ThemeContext)

    useEffect(() => {
        chatStore.updateTitle('')
    }, [])

    return (
        <>
            <div className={`flex flex-col items-center text-sm h-full  ${theme==='dark' ? "bg-lightBlack" : "bg-white"} overflow-y-auto`} ref={chatBodyRef} >
                <div className="flex w-full flex-wrap flex-col items-center  text-gray-300 pt-[60px]" >
                    <Welcome />

                    <div className="justify-center w-full md:max-w-2xl lg:max-w-3xl flex flex-col pt-10 px-20 pb-6 mb-6 rounded-md transition-colors duration-200">
                        
                        <div className="flex flex-col md:flex-row text-base gap-2 justify-between">
                            <p class={`flex items-center ${theme==='dark' ? "text-gray-100/50" : "text-lightBlack"} text-sm font-light mb-2`}>
                                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-600 text-white rounded-full flex-shrink-0">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>好用的GPT，无需关心细节
                            </p>
                            <p class={`flex items-center ${theme==='dark' ? "text-gray-100/50" : "text-lightBlack"} text-sm font-light mb-2`}>
                                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-600 text-white rounded-full flex-shrink-0">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>界面简洁、清晰
                            </p>
                        </div>

                        <div className="flex flex-col md:flex-row text-base gap-2 mt-1 justify-between">
                            <p class={`flex items-center ${theme==='dark' ? "text-gray-100/50" : "text-lightBlack"} text-sm font-light mb-2`}>
                                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-600 text-white rounded-full flex-shrink-0">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>无需按月订购，基于Token数量使用限制
                            </p>
                            <p class={`flex items-center ${theme==='dark' ? "text-gray-100/50" : "text-lightBlack"} text-sm font-light mb-2`}>
                                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-600 text-white rounded-full flex-shrink-0">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>免费的额度体验
                            </p>
                        </div>

                        <div className="flex flex-col md:flex-row text-base gap-2 mt-1 justify-between">
                            <p class={`flex items-center ${theme==='dark' ? "text-gray-100/50" : "text-lightBlack"} text-sm font-light mb-2`}>
                                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-600 text-white rounded-full flex-shrink-0">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>官方token数限制逐步放开
                            </p>
                            <p class={`flex items-center ${theme==='dark' ? "text-gray-100/50" : "text-lightBlack"} text-sm font-light mb-2`}>
                                <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-600 text-white rounded-full flex-shrink-0">
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                        <path d="M20 6L9 17l-5-5"></path>
                                    </svg>
                                </span>更多功能在路上
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default observer(Home)