import { useStore } from "@/store"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import { Alert, Button, Dialog, IconButton, Snackbar, TextField } from "@mui/material";
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const RegisteModal = (props)=>{
    const {sessionStore} = useStore()

    const [nickName, setNickName] = useState()
    const [email, setEmail] = useState()
    const [passwd, setPasswd] = useState()
    const [confirmPwd, setConfirmPwd] = useState()

    const handleNickNameClick = (event)=>{
        setNickName(event.target.value)
    }
    const handleEmailClick = (event)=>{
        setEmail(event.target.value)
    }
    const handlePasswdClick = (event)=>{
        setPasswd(event.target.value)
    }
    const handleConfirmPwdClick = (event)=>{
        setConfirmPwd(event.target.value)
    }

    const isEmail = (email)=>{
        if(email==null || email==undefined){
            return false;
        }
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const handleRegisteClick = ()=>{
        if(nickName==null || nickName=='' || nickName==undefined ||  email==null || email=='' || email==undefined || passwd==null || passwd=='' || passwd==undefined){
            props.showAlert({
                alertType: 'error',
                alertStatus: true,
                alertMsg: '输入内容错误'
            })
            return
        }

        if(!isEmail(email)){
            props.showAlert({
                alertType: 'error',
                alertStatus: true,
                alertMsg: 'Email格式有误'
            })
            return
        }

        if(passwd!=confirmPwd){
            props.showAlert({
                alertType: 'error',
                alertStatus: true,
                alertMsg: '密码输入不一致'
            })
            return
        }

        //调用其他组件的方法handleRegiste
        props.handleRegiste({
            url: 'api/ilovegpt/register',
            nickName: nickName,
            email: email,
            passwd: passwd
        })
    }

    const handleClickLoginOpen =()=>{
        sessionStore.registeOpen = false
        sessionStore.loginOpen = true
    }

    const handleRegisteClose = ()=>{
        sessionStore.registeOpen = false
    }

    return(
        <Dialog open={sessionStore.registeOpen} onClose={handleRegisteClose}>
            <DialogTitle>注册</DialogTitle>
            <DialogContent>

                <TextField
                    autoFocus
                    margin="dense"
                    id="nickName"
                    label="昵称"
                    type="text"
                    value={nickName}
                    onChange={handleNickNameClick}
                    fullWidth
                    variant="standard"
                />

                <TextField
                    margin="dense"
                    id="email"
                    label="Email"
                    type="email"
                    value={email}
                    onChange={handleEmailClick}
                    fullWidth
                    variant="standard"
                />

                <TextField
                    margin="dense"
                    id="passwd"
                    label="密码"
                    type="password"
                    value={passwd}
                    onChange={handlePasswdClick}
                    fullWidth
                    variant="standard"
                />

                <TextField
                    margin="dense"
                    id="confirmPwd"
                    label="确认密码"
                    type="password"
                    value={confirmPwd}
                    onChange={handleConfirmPwdClick}
                    fullWidth
                    variant="standard"
                />

                <DialogContentText><span className="text-xs mt-6">已经有账号?&nbsp;去<span className="cursor-pointer font-semibold" onClick={handleClickLoginOpen}>登录</span></span></DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleRegisteClose}>取消</Button>
                <Button onClick={handleRegisteClick}>注册</Button>
            </DialogActions>
        </Dialog>
    )
}
export default observer(RegisteModal)