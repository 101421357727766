import axios from 'axios'

const http = axios.create({
    baseURL: "https://ilovegpt.net",  //线上
    // baseURL: "http://localhost:8089", //开发
    timeout: 300000
})

http.interceptors.request.use((config) => { 
    let token = localStorage.getItem('token')
    config.headers.token = token
    config.headers.appId = 'ilovegpt-1094244909482770432' //写死的代码
    return config
}, (error) => {
    return Promise.reject(error)
})

http.interceptors.response.use((response) => {
    let token = response.headers.get('token')
    if(token!=null && token!=undefined && token!=''){
        localStorage.setItem('token', token)

        if(localStorage.getItem('token')!=null){
            console.log('存储token成功....' + localStorage.getItem('token'))
        }
    }
    return response.data
}, (error) => {
    return Promise.reject(error)
})
export {http}