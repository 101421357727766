import { useStore } from "@/store"
import { observer } from "mobx-react-lite"
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Button, Dialog, IconButton, Snackbar, TextField } from "@mui/material";

const WechatModal = ()=>{
    const {appStore} = useStore()

    const handleWechatClose = ()=>{
        appStore.wechatOpen = false
    }

    return(
        <Dialog open={appStore.wechatOpen} onClose={handleWechatClose} >
            {/* <DialogTitle>wechat</DialogTitle> */}
            <DialogContent className="bg-lightBlack">
                <img src="./wechat.jpg" />
            </DialogContent>
        </Dialog>
    )
}
export default observer(WechatModal)