import React, { useState } from "react";
import Router from "./pages/router";
import { ThemeProvider } from "./theme/themeContext";

export default function App() {
  return (
    <ThemeProvider>
      <Router />
    </ThemeProvider>
  );
}
