import useDataApi from "@/hooks/useDataApi";
import { useStore } from "@/store";
import { ThemeContext } from "@/theme/themeContext";
import { DataThresholdingRounded } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PlanPrice = (props) => {
    const {appStore, sessionStore} = useStore()
    const [{dataResult, isLoading, isError, pageNum}, setQueryParams] = useDataApi({})
    const [freePlanId, setFreePlanId] = useState('')
    const [proPlanId, setProPlanId] = useState('')
    const [orderOnToggle, setOrderOnToggle] = useState(false)
    const {theme, toggleTheme} = useContext(ThemeContext)

    useEffect(()=>{
        if(props.planData==null || props.planData.code!==200 || props.planData.data.length<=0){
            props.showAlert({
                alertType: 'error',
                alertStatus: true,
                alertMsg: '计划加载失败'
            })
        }else {
            props.planData.data.map((plan, index)=>{
                if(plan.name==='FREE'){
                    setFreePlanId(plan.id)
                }
                if(plan.name==='PRO'){
                    setProPlanId(plan.id)
                }
            })
        }
    }, [props.planData])

    /**
     * 升级套餐，后台创建订单
     * 
     * @param {*} _planId 
     */
    const handleCheckout = (_planId)=>{
        if (!sessionStore.isLogin()) {
            appStore.planOpen = false
            sessionStore.loginOpen = true
            return
        }

        if(_planId=='' || !_planId){
            props.showAlert({
                alertType: 'error',
                alertStatus: true,
                alertMsg: '无法获取计划ID'
            })
            return
        }
        setOrderOnToggle(true)
        setQueryParams({
            url: '/api/ilovegpt/order',
            planId: _planId
        })
    }

    useEffect(()=>{
        const invokeCheckout = ()=>{
            if(dataResult!=null && dataResult.code===200 && dataResult.data!=undefined && dataResult.data!=''){
                const order_id = dataResult.data

                //lemonsqueezy生产
                // window.location.href = 'https://ilovegpt.lemonsqueezy.com/checkout/buy/6612c196-f16b-49a9-a34b-b6a9c10597a6?checkout[custom][orderId]=' + order_id
                window.open('https://ilovegpt.lemonsqueezy.com/checkout/buy/6612c196-f16b-49a9-a34b-b6a9c10597a6?checkout[custom][orderId]=' + order_id)

                //lemonsqueezy测试
                //  window.open('https://ilovegpt.lemonsqueezy.com/checkout/buy/ce09a7bd-9f1c-4a6e-b378-1a76ab4fee24?checkout[custom][orderId]=' + order_id)
            }else{
                props.showAlert({
                    alertType: 'error',
                    alertStatus: true,
                    alertMsg: '订单创建失败'
                })
            }
            setOrderOnToggle(false)
            appStore.planOpen = false
        }

        if(dataResult==null || dataResult.code===500 || (Array.isArray(dataResult) && dataResult.length<=0)){
            //后台服务断掉或者提交订单参数有误
            if(isError || (dataResult.code===500 && dataResult.msg==='Param is invalid')){
                props.showAlert({
                    alertType: 'error',
                    alertStatus: true,
                    alertMsg: '订单创建失败'
                })
            }
            setOrderOnToggle(false)
        }else {
            invokeCheckout()
        }
    }, [dataResult, isError])

    return (
        <>
            <section class="text-gray-600 body-font h-full overflow-hidden">
                <div class="container px-5 py-4 mx-auto">
                    
                    {/* <div class="flex flex-col text-center w-full mb-20">
                        <h1 class="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900">Pricing</h1>
                        <p class="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-500">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical.</p>
                        <div class="flex mx-auto border-2 border-indigo-500 rounded overflow-hidden mt-6">
                            <button class="py-1 px-4 bg-indigo-500 text-white focus:outline-none">Monthly</button>
                            <button class="py-1 px-4 focus:outline-none">Annually</button>
                        </div>
                    </div> */}

                    <div class="flex flex-col md:flex-row">

                        <div class="p-4 w-full md:w-1/2">
                            <div class="h-full p-6 rounded-lg ring-1 ring-gray-500 flex flex-col relative overflow-hidden">

                                <h2 class={` text-sm tracking-widest title-font mb-1 font-medium ${theme==='dark' ? "text-gray-400" : "text-gray-700" }  `}>FREE</h2>

                                <h1 class={` text-2xl ${theme==='dark' ? "text-gray-100" : "text-black" } leading-none flex items-center pb-4 mb-4 border-b border-gray-200 `}>
                                    <span>¥&nbsp;0.0</span>
                                    {/* <span class="text-lg ml-1 font-normal text-gray-400">/月</span> */}
                                </h1>
                                <p class={` flex items-center  ${theme==='dark' ? "text-gray-100/50" : "text-black" }   text-sm font-light mb-2 `}>
                                    <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-600 text-white rounded-full flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                            <path d="M20 6L9 17l-5-5"></path>
                                        </svg>
                                    </span>10,000&nbsp;token
                                </p>
                                <p class={` flex items-center  ${theme==='dark' ? "text-gray-100/50" : "text-black" } text-sm font-light mb-2 `}>
                                    <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-600 text-white rounded-full flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                            <path d="M20 6L9 17l-5-5"></path>
                                        </svg>
                                    </span>登录即获取
                                </p>
                                <p class={` flex items-center  ${theme==='dark' ? "text-gray-100/50" : "text-black" } text-sm font-light mb-8 `}>
                                    <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-600 text-white rounded-full flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                            <path d="M20 6L9 17l-5-5"></path>
                                        </svg>
                                    </span>无客户服务
                                </p>
                            </div>
                        </div>

                        <div class="p-4 w-full md:w-1/2">
                            <div class="h-full p-6 rounded-lg ring-1 ring-gray-500 flex flex-col relative overflow-hidden">
                                <span class="bg-red-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">最受欢迎</span>
                                
                                <h2 class={` text-sm tracking-widest title-font mb-1 font-medium ${theme==='dark' ? "text-gray-400" : "text-gray-700" } `}>PRO</h2>

                                <h1 class={` text-2xl ${theme==='dark' ? "text-gray-100" : "text-black"} leading-none flex items-center pb-4 mb-4 border-b border-gray-200 `}>
                                    <span>¥&nbsp;29.9</span>
                                    {/* <span class="text-lg ml-1 font-normal text-gray-400">/月</span> */}
                                </h1>

                                {/* <p class="flex items-center text-gray-100/50 text-sm font-light mb-2">
                                    <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-600 text-white rounded-full flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                            <path d="M20 6L9 17l-5-5"></path>
                                        </svg>
                                    </span>聊天功能
                                </p>
                                <p class="flex items-center text-gray-100/50 text-sm font-light mb-2">
                                    <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-600 text-white rounded-full flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                            <path d="M20 6L9 17l-5-5"></path>
                                        </svg>
                                    </span>办公室主任功能
                                </p> */}
                                
                                {/* <p class="flex items-center text-gray-100/50 text-sm font-light mb-2">
                                    <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-600 text-white rounded-full flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                            <path d="M20 6L9 17l-5-5"></path>
                                        </svg>
                                    </span>50,000&nbsp;token
                                </p> */}
                                
                                <p class={` flex items-center  ${theme==='dark' ? "text-gray-100/50" : "text-black" } text-sm font-light mb-2 `}>
                                    <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-600 text-white rounded-full flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                            <path d="M20 6L9 17l-5-5"></path>
                                        </svg>
                                    </span>1,000,000&nbsp;token
                                </p>
                                <p class={` flex items-center  ${theme==='dark' ? "text-gray-100/50" : "text-black" } text-sm font-light mb-2 `}>
                                    <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-600 text-white rounded-full flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                            <path d="M20 6L9 17l-5-5"></path>
                                        </svg>
                                    </span>解锁所有功能
                                </p>
                                <p class={` flex items-center  ${theme==='dark' ? "text-gray-100/50" : "text-black" } text-sm font-light mb-8 `}>
                                    <span class="w-4 h-4 mr-2 inline-flex items-center justify-center bg-green-600 text-white rounded-full flex-shrink-0">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.5" class="w-3 h-3" viewBox="0 0 24 24">
                                            <path d="M20 6L9 17l-5-5"></path>
                                        </svg>
                                    </span>VIP客户服务
                                </p>
                                <button class={`flex items-center mt-auto text-white bg-red-500 border-0 py-2 px-4 w-full focus:outline-none hover:bg-red-400 rounded  disabled:opacity-50`}  disabled={orderOnToggle}  onClick={()=>handleCheckout(proPlanId)} >升级
                                    <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-auto" viewBox="0 0 24 24">
                                        <path d="M5 12h14M12 5l7 7-7 7"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}
export default PlanPrice;