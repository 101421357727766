import { useStore } from "@/store"
import { observer } from "mobx-react-lite"
import { useState } from "react"
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert, Button, Dialog, IconButton, Snackbar, TextField } from "@mui/material";
import { useOutletContext } from "react-router-dom";

const LoginModal = (props)=>{
    const {sessionStore} = useStore()

    const [email, setEmail] = useState()
    const [passwd, setPasswd] = useState()

    const handleEmailClick = (event)=>{
        setEmail(event.target.value)
    }
    const handlePasswdClick = (event)=>{
        setPasswd(event.target.value)
    }

    const isEmail = (email)=>{
        if(email==null || email==undefined){
            return false;
        }
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const handleLoginClick = ()=>{
        if(email==null || email=='' || email==undefined || passwd==null || passwd=='' || passwd==undefined){
            props.showAlert({
                alertType: 'error',
                alertStatus: true,
                alertMsg: '输入内容错误'
            })
            return
        }

        if(!isEmail(email)){
            props.showAlert({
                alertType: 'error',
                alertStatus: true,
                alertMsg: 'Email格式有误'
            })
            return
        }

        //调用其他组件的方法handleLogin
        props.handleLogin({
            url: 'api/ilovegpt/login',
            email: email,
            passwd: passwd
        })
    }

    const handleLoginClose = ()=>{
        sessionStore.loginOpen = false
    }

    const handleClickRegisteOpen = ()=>{
        sessionStore.loginOpen = false
        sessionStore.registeOpen = true
    }

    return(
        <Dialog open={sessionStore.loginOpen} onClose={handleLoginClose}>
            <DialogTitle>登录</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="email"
                    label="Email"
                    type="email"
                    value={email}
                    onChange={handleEmailClick}
                    fullWidth
                    variant="standard"
                />

                <TextField
                    margin="dense"
                    id="passwd"
                    label="密码"
                    type="password"
                    value={passwd}
                    onChange={handlePasswdClick}
                    fullWidth
                    variant="standard"
                />

                <DialogContentText><span className="text-xs mt-6">没有账号?&nbsp;去<span className="cursor-pointer font-semibold" onClick={handleClickRegisteOpen}>注册</span></span></DialogContentText>

            </DialogContent>

            <DialogActions>
                <Button onClick={handleLoginClose}>取消</Button>
                <Button onClick={handleLoginClick}>登录</Button>
            </DialogActions>
        </Dialog>
    )
}
export default observer(LoginModal)