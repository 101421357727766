import { makeAutoObservable } from 'mobx'

class ChatStore{
    //是否消耗token超出了套餐
    tokenOverload = false

    //会话标题
    chatTitle = '新会话'
    //本次对话消耗的token
    tokenNum = 0
    //对话次数
    chatNum = 0

    //用户消耗的总token
    consumeQuantity = 0
    //用户订单的总token
    quantity = 0
    //计划名称
    planName = 'FREE'
    
    //opeanAI接口调用中状态
    openAIOnToggle = false

    //系统角色山下文内容
    systemContent = ''

    //存储了当前对话的所有数据，包括对话中的每条数据的状态
    chatEntityList = []

    constructor(){
        makeAutoObservable(this)
    }

    updateTitle(newTitle){
        this.chatTitle = newTitle
    }

    /**
     * 更新系统上下文内容
     * 
     * @param {*} newSystemContent 
     */
    updateSystemContent(newSystemContent){
        if(newSystemContent!=null && newSystemContent!=undefined){
            this.systemContent = newSystemContent
        }
    }

    updateTokenNum(newTokenNum){
        this.tokenNum = this.tokenNum + newTokenNum
    }

    updateChatEntityList(newChatEntityList){
        var _tokenNum = 0
        if(newChatEntityList==null || newChatEntityList==undefined){
            this.chatEntityList = []
        }else{
            this.chatEntityList = newChatEntityList
        }
        this.chatNum = this.chatEntityList.length;
        if(this.chatEntityList.length>0){
            this.chatEntityList.map((item, index)=>{
                if(item.totalToken)
                _tokenNum = _tokenNum + item.totalToken
            })
        }
        this.tokenNum = _tokenNum
    }

    /**
     * 更新当前登录用户的所有订阅的token数量
     * @param {*} newQuantity 
     */
    updateQuantity(newQuantity){
        this.quantity = newQuantity
    }

    resetConsumeQuantity(consumeQuantity){
        this.consumeQuantity = consumeQuantity
    }

    resetPlanName(planName){
        if(planName!='' && planName!=null){
            this.planName = planName
        }
    }

    /**
     * 更新消耗token的总和
     * @param {*} newTokenNum 
     */
    updateConsumeQuantity(newTokenNum){
        let _consumeQuantity = this.consumeQuantity + newTokenNum
        if(_consumeQuantity>=this.quantity){
            this.tokenOverload = true
            this.consumeQuantity = this.quantity //不显示超出的部分token
        }else{
            this.consumeQuantity = _consumeQuantity
        }
    }

    //当问题是重复的时候，就不能及时更新最新的提问了 -- 废弃
    updateCompletionStatus00(chatEntity, completionStatus){
        const index = this.chatEntityList.findIndex(item => item.chatRequestion === chatEntity.chatRequestion);
        if (index !== -1) {
            const newList = [...this.chatEntityList];
            newList[index] = { ...newList[index], status: completionStatus, chatAnswer: chatEntity.chatAnswer };
            this.chatEntityList = newList
        }
    }

    //取当前对话所有数据的最后一条记录即可，然后更新其数据
    updateCompletionStatus(chatEntity, completionStatus){
        const newList = [...this.chatEntityList]
        let index = this.chatEntityList.length-1
        newList[index] = { ...newList[index], status: completionStatus, chatAnswer: chatEntity.chatAnswer, totalToken: chatEntity.totalToken };
        this.chatEntityList = newList
    }
}
export default ChatStore