import { useStore } from "@/store"
import { ThemeContext } from "@/theme/themeContext"
import { observer } from "mobx-react-lite"
import { useContext } from "react"

const ChatTitle = () => {
    const { chatStore } = useStore()
    const {theme, toggleTheme} = useContext(ThemeContext)

    return (
        <>
            <div className={`${theme==='dark'? "text-gray-200" : "text-lightBlack font-medium"} text-base flex flex-col items-center`}>
                <div>{chatStore.chatTitle}</div>
                {
                    (chatStore.chatTitle==='' || chatStore.chatTitle===undefined) ? '' : <div className={`${theme==='dark' ? "text-gray-100/50" : "text-gray-500"} text-xs pt-1`}>GPT-3.5-turbo&nbsp;•&nbsp;{chatStore.chatEntityList.length}&nbsp;次对话&nbsp;•&nbsp;{chatStore.tokenNum}&nbsp;tokens</div>
                }
            </div>
        </>
    )
}
export default observer(ChatTitle)